<template>
  <teleport to="head">
    <title>{{ meta.title }}</title>
    <meta name="og:title" property="og:title" :content="meta.title" />
    <meta name="description" :content="meta.description" />
    <meta
      name="og:description"
      property="og:description"
      :content="meta.description"
    />
    <meta name="og:image" property="og:image" :content="meta.image" />
    <meta name="og:url" property="og:url" :content="meta.url" />
    <link rel="canonical" :href="meta.url" />
  </teleport>

  <div
    class="app__wrapper"
    :class="{ 'app__wrapper--full': fullPage }"
    v-keyup="onEsc"
  >
    <PreLoader />

    <div
      v-if="flats"
      id="appScroll"
      class="app__scroll"
      ref="scroll"
      :class="{ 'app__scroll--disabled': noScroll }"
      tabindex="-1"
    >
      <PageHeader />
      <router-view class="app__layout" />
    </div>

    <CallMe />
    <MainNav />
    <CardCookies />
  </div>
</template>

<script>
import PreLoader from "@/components/PreLoader";
import CallMe from "@/components/CallMe";
import MainNav from "@/components/MainNav";
import PageHeader from "@/components/PageHeader";
import CardCookies from "@/components/CardCookies";
import flatsBase from "@/json/flats.json";

export default {
  name: "App",
  components: {
    PreLoader,
    CallMe,
    MainNav,
    PageHeader,
    CardCookies
  },
  data() {
    return {
      flatsBase: flatsBase,
      noScroll: false
    };
  },
  computed: {
    meta() {
      let title = "Panorama Residence";
      const routeTitle = this.$te("pages." + this.$route.name + ".title")
        ? this.$t("pages." + this.$route.name + ".title")
        : "";
      if (routeTitle && routeTitle != title) title = title + " | " + routeTitle;

      const id = this.$route.params.id;
      if (id) title = title + " " + id;

      return {
        title: title,
        description: this.$t("description"),
        image: this.$store.state.domain + "/img/panorama.jpg",
        url:
          this.$store.state.domain +
          this.$localizePath(this.$route.path, this.$i18n.locale)
      };
    },
    api() {
      return this.$store.state.api;
    },
    locale() {
      return this.$i18n.locale;
    },
    flats() {
      return this.$store.state.flats;
    },
    fullPage() {
      return this.$route.name === "index" || this.$route.name === "process";
    }
  },
  methods: {
    onEsc(event) {
      if (event.keyCode == 27) {
        this.$store.state.callMeActive = false;
        this.$store.state.requestActive = false;
        this.$store.state.filterActive = false;
        this.$store.state.navActive = false;
      }
    },
    getFalts() {
      this.$axios({
        method: "get",
        url: this.api + "/flats",
        timeout: 90000
      })
        .then((response) => {
          if (response && response.data) {
            this.$store.state.contacts = response.data.contacts || {};
            this.$store.state.per_sqr = +response.data.per_sqr || 2000;
            this.$store.state.price_on_request =
              response.data.price_on_request || 0;

            const flats = response.data.flats || {};
            const flatsKeys = Object.keys(flats);

            flatsKeys.forEach((id, index) => {
              flats[id].id = id;
              flats[id].layout = "/base/flats/" + id + ".svg";
              flats[id].furniture = "/base/furniture/" + id + ".svg";

              const baseFlat = flatsBase[id];

              flats[id].textX = baseFlat.textX;
              flats[id].textY = baseFlat.textY;

              flats[id].highlight = baseFlat.highlight;

              if (baseFlat.highlight_1) {
                flats[id].floor_1 = +flats[id].floor + 1;

                flats[id].highlight_1 = baseFlat.highlight_1;

                flats[id].layout_1 = "/base/flats/" + id + "_2.svg";
                flats[id].furniture_1 = "/base/furniture/" + id + "_2.svg";
              }

              if (index === flatsKeys.length - 1) {
                //prerender only-----
                // localStorage.setItem(
                //   "VueAppFlats",
                //   JSON.stringify({
                //     flats: flats,
                //     date: Date.now()
                //   })
                // );
                //prerender only-----

                this.$store.state.flats = flats;
              }
            });
          } else {
            this.$toast.error(this.$t("error"));
          }
        })
        .catch((error) => {
          this.$toast.error(this.$t("error"));
          if (error && error.response) console.log(error.response);
        });
    }
  },
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (to.name === from.name) {
        next();
      } else {
        this.$emitter.emit("show-preloader");
        setTimeout(() => {
          this.$store.state.navActive = false;
          this.$store.state.callMeActive = false;
          next();
        }, 750);
      }
    });
    this.$router.afterEach(() => {
      this.$scrollTo("#appScroll", 0, {
        container: "#appScroll"
      });

      this.$nextTick(() => {
        this.$store.state.navActive = false;
        this.$store.state.callMeActive = false;
        if (this.$refs.scroll) this.$refs.scroll.focus();
      });
    });

    this.$axios.defaults.headers.common["X-localization"] = this.locale;

    //prerender only-----
    // let storageFlats = localStorage.getItem("VueAppFlats");
    // if (storageFlats) {
    //   storageFlats = JSON.parse(storageFlats);
    //   if (Date.now() - storageFlats.date < 60000) {
    //     this.$store.state.flats = storageFlats.flats;
    //   } else {
    //     this.getFalts();
    //   }
    // } else {
    //   this.getFalts();
    // }
    //prerender only-----

    this.getFalts();
  },
  mounted() {
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i)
    ) {
      document.documentElement.classList.add("is-ios");
    }
    if (this.$refs.scroll) this.$refs.scroll.focus();
  }
};
</script>

<style lang="less">
@import "styles/styles.less";
.app {
  height: 100%;
  &__wrapper {
    box-sizing: border-box;
    height: 100%;
    @media @bw1020 {
      padding-top: 135px;
    }
    @media @bw768 {
      padding-top: 115px;
    }
    @media @bw400 {
      padding-top: 100px;
    }
    &--full {
      padding: 0 !important;
    }
  }
  &__scroll {
    height: 100%;
    .custom-scroll;
    outline: none;
    &--disabled {
      overflow: hidden;
    }
  }
  &__layout {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__page {
    box-sizing: border-box;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
</style>
