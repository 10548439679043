<template>
  <nav class="main-nav" :class="{ 'main-nav--active': navActive }">
    <div class="main-nav__scroll">
      <div class="main-nav__scroll-container">
        <div class="main-nav__big-word big-word">menu</div>

        <LangSwitcher class="main-nav__lang-switcher" />

        <div class="main-nav__inner">
          <div class="main-nav__item main-nav__item--mb45">
            <div href="#" class="main-nav__heading">
              {{ $t("nav.about") }}
            </div>
            <div class="main-nav__sub-links">
              <localized-link to="/architecture" class="main-nav__sub-link">
                {{ $t("nav.architecture") }}
              </localized-link>
              <localized-link to="/design" class="main-nav__sub-link">
                {{ $t("nav.design") }}
              </localized-link>
              <localized-link to="/improvement" class="main-nav__sub-link">
                {{ $t("nav.improvement") }}
              </localized-link>
              <localized-link to="/parking" class="main-nav__sub-link">
                {{ $t("nav.parking") }}
              </localized-link>
              <localized-link to="/location" class="main-nav__sub-link">
                {{ $t("nav.location") }}
              </localized-link>
              <localized-link to="/developer" class="main-nav__sub-link">
                {{ $t("nav.developer") }}
              </localized-link>
            </div>
          </div>

          <div class="main-nav__item">
            <localized-link to="/flats" class="main-nav__link">
              {{ $t("nav.flats") }}
            </localized-link>

            <div class="main-nav__sub-links main-nav__sub-links--desktop">
              <localized-link
                :to="{ path: '/flats' }"
                class="main-nav__params-link"
                :class="{
                  'main-nav__params-link--active':
                    $route.name == 'flats' && $route.query.layouts != '1'
                }"
              >
                {{ $t("nav.floor") }}
              </localized-link>
              <localized-link
                :to="{ name: 'flats', query: { layouts: 1 } }"
                class="main-nav__params-link"
                :class="{
                  'main-nav__params-link--active':
                    $route.name == 'flats' && $route.query.layouts == '1'
                }"
              >
                {{ $t("nav.layout") }}
              </localized-link>
            </div>
          </div>
          <localized-link
            to="/contacts"
            class="main-nav__link main-nav__link--mr0"
          >
            {{ $t("nav.contacts") }}
          </localized-link>

          <a v-if="phone" class="main-nav__phone" :href="`tel:${phoneNumber}`">
            {{ phone }}
          </a>
        </div>
        <div class="main-nav__footer">
          <localized-link to="/process" class="main-nav__footer-link">
            {{ $t("nav.process") }}
          </localized-link>
          <localized-link
            to="/documentation"
            class="main-nav__footer-link main-nav__footer-link--mb20"
          >
            {{ $t("nav.documentation") }}
          </localized-link>
          <localized-link to="/cookies" class="main-nav__doc-link">
            {{ $t("nav.cookies") }}
          </localized-link>
          <localized-link
            to="/privacy"
            class="main-nav__doc-link main-nav__doc-link--mb20"
          >
            {{ $t("nav.privacy") }}
          </localized-link>
          <div class="main-nav__copyright">
            {{ $t("nav.creation") }}
            <a
              rel="nofollow noreferrer"
              target="_blank"
              class="main-nav__copyright-link"
            >
              <svg-icon
                width="71"
                height="18"
                name="cybeam"
                class="main-nav__devs-logo"
              />
            </a>
          </div>
          <div class="main-nav__text">
            ©
            {{ new Date().getFullYear() }}
            Panorama
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import LangSwitcher from "@/components/LangSwitcher";

export default {
  components: {
    LangSwitcher
  },
  data() {
    return {};
  },
  computed: {
    navActive() {
      return this.$store.state.navActive;
    },
    contacts() {
      return this.$store.state.contacts;
    },
    phone() {
      return this.contacts.phone || "";
    },
    phoneNumber() {
      return this.$store.getters.phoneNumber;
    }
  },
  watch: {
    navActive: {
      handler: function (val) {
        if (!val && this.$root.$refs.scroll) this.$root.$refs.scroll.focus();
      },
      immediate: false
    }
  }
};
</script>

<style scoped lang="less">
.main-nav {
  overflow: hidden;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: @white;
  transform: translate3d(0%, -100%, 0);
  transition: transform 0.4s;
  background-color: @bg;
  z-index: 900;
  @media @bw1020 {
    padding: 130px 0px 0px;
  }
  @media @bw768 {
    padding: 115px 0px 0px;
  }
  @media @bw400 {
    padding: 100px 0 0;
  }
  &::after {
    content: "";
    position: absolute;
    top: -330px;
    right: 133px;
    width: 1067px;
    height: 1823px;
    background-image: url("~@/assets/images/bg_logo.svg");
    background-repeat: no-repeat;
    opacity: 0.05;
    z-index: -2;
    @media @bw1340 {
      top: -158px;
      right: 70px;
      width: 642px;
      height: 1096px;
    }
    @media @bw1020 {
      top: -85px;
      right: -510px;
      width: 811px;
      height: 1385px;
    }
    @media @bw768 {
      top: -200px;
      right: -660px;
    }
    @media @bw400 {
      top: -60px;
      right: -304px;
      width: 411px;
      height: 703px;
    }
  }
  &--active {
    transform: translate3d(0, 0, 0);
  }
  &__scroll {
    .custom-scroll-light;
    box-sizing: border-box;
    height: 100%;
  }
  &__scroll-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;
    padding: 300px 0 0;
    @media @bw1340 {
      padding: 190px 0 0;
    }
    @media @bw1020 {
      padding: 60px 0 0 0;
    }
    @media @bw768 {
      padding: 40px 0 0 0;
    }
    @media @bw400 {
      padding: 30px 0 0 0;
    }
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
      width: 628px;
      height: 816px;
      background-image: url("~@/assets/images/building_d.png");
      background-repeat: no-repeat;
      @media @bw1510 {
        width: 413px;
        height: 552px;
      }
      @media @bw768 {
        background-image: url("~@/assets/images/building_m.png");
        width: 148px;
        height: 461px;
      }
    }
  }
  &__item {
    display: flex;
    margin-bottom: 60px;
    @media @bw1440 {
      margin-bottom: 50px;
      flex-direction: column;
    }
    @media @bw400 {
      margin-bottom: 40px;
    }
    &--mb45 {
      @media @bw1170 {
        margin-bottom: 45px;
      }
    }
  }
  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    flex-grow: 1;
    padding: 0 50px;
    @media @bw1340 {
      padding: 0 30px;
    }
    @media @bw1170 {
      padding: 0 80px 0 30px;
      justify-content: flex-start;
    }
    @media @bw768 {
      padding: 0 70px 0 20px;
    }
    @media @bw400 {
      padding: 0 70px 0 20px;
    }
  }
  &__heading,
  &__link {
    display: block;
    align-self: flex-start;
    margin-right: 50px;
    text-transform: uppercase;
    text-decoration: none;
    font-family: @font2;
    color: @white;
    font-weight: 700;
    font-size: 72px;
    line-height: 1;
    @media @bw1440 {
      margin-right: 0;
      font-size: 52px;
    }
    @media @bw768 {
      font-size: 36px;
    }
    @media @bw370 {
      font-size: 28px;
    }
  }
  &__heading {
    cursor: default;
  }
  &__link {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px;
      width: 100%;
      background-color: @white;
      transform: scaleX(1);
      transition: transform 0.3s ease-out;
      transform-origin: 0 100%;
    }
    &:hover {
      &::after {
        transform: scaleX(0);
      }
    }
    &--mr0 {
      margin-right: 0;
    }
    &.router-link-exact-active {
      color: @gold;
      cursor: default;
      &::after {
        background-color: @gold;
      }
    }
  }
  &__sub-links {
    display: flex;
    align-items: flex-end;
    @media @bw1440 {
      margin-top: 10px;
    }
    @media @bw1170 {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 15px;
    }
    &--desktop {
      @media @bw1170 {
        display: none;
      }
    }
  }
  &__sub-link,
  &__params-link,
  &__doc-link {
    display: block;
    padding: 10px 0;
    font-weight: 500;
    font-family: @font1;
    line-height: 1.6;
    color: @white_60;
    transition: color 0.2s;
    @media @bw1170 {
      padding: 5px 0;
    }
    @media @bw768 {
      line-height: 1.25;
    }
    &:hover,
    &:active {
      color: @gold;
    }
  }
  &__sub-link,
  &__params-link {
    margin-right: 30px;
    text-decoration: none;
    font-size: 16px;
    @media @bw1170 {
      margin-right: 0;
      margin-bottom: 10px;
    }
    &--active {
      color: @gold;
      cursor: default;
    }
    &:last-child {
      margin-right: 0;
      @media @bw1170 {
        margin-bottom: 0;
      }
    }
  }
  &__sub-link,
  &__doc-link,
  &__footer-link {
    &.router-link-exact-active {
      color: @gold;
      cursor: default;
    }
  }
  &__doc-link {
    display: none;
    @media @bw768 {
      display: block;
      margin-bottom: 10px;
      text-decoration: underline;
      font-size: 12px;
      line-height: 1.16;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &--mb20 {
      @media @bw768 {
        margin-bottom: 20px;
      }
    }
  }
  &__phone {
    display: none;
    @media @bw1020 {
      display: block;
      margin-top: 25px;
      padding: 10px 0;
      font-family: @font2;
      font-size: 22px;
      line-height: 1;
      color: @white;
      text-decoration: none;
      transition: 0.2s;
      &:hover,
      &:active {
        color: @gold;
      }
    }
    @media @bw768 {
      margin-bottom: 20px;
    }
    @media @bw400 {
      margin-top: auto;
    }
  }
  &__copyright {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.85;
    color: @white_60;
    @media @bw768 {
      margin-bottom: 30px;
    }
  }
  &__copyright-link {
    display: flex;
    margin-left: 10px;
  }
  &__text {
    display: none;
    @media @bw768 {
      display: block;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.16;
      color: @white;
    }
  }
  &__big-word {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0.53em;
    font-size: 800px;
    letter-spacing: 0.3em;
    @media @bw1680 {
      font-size: 800px;
    }
    @media @bw1440 {
      font-size: 650px;
    }
    @media @bw1170 {
      font-size: 460px;
    }
    @media @bw768 {
      display: none;
    }
    &::before {
      content: "";
      width: 20px;
      display: block;
      margin-left: -100px;
      @media @bw1680 {
        margin-left: -100px;
      }
      @media @bw1440 {
        margin-left: 0px;
      }
      @media @bw1340 {
        margin-left: 400px;
      }
      @media @bw1170 {
        margin-left: 0;
      }
    }
  }
  &__devs-logo {
    display: block;
    color: @gold;
  }
  &__lang-switcher {
    display: none;
    @media @bw1020 {
      display: block;
      position: absolute;
      top: 60px;
      right: 30px;
    }
    @media @bw768 {
      top: 30px;
      right: 20px;
    }
    @media @bw400 {
      top: 20px;
    }
  }
  &__footer {
    display: flex;
    align-items: flex-end;
    margin-top: auto;
    padding: 0 50px 50px;
    min-height: 100px;
    background: linear-gradient(
      180deg,
      rgba(64, 72, 83, 0) 0%,
      rgba(64, 72, 83, 0.7) 39.58%,
      #404853 100%
    );
    @media @bw1340 {
      padding: 0 30px 30px;
    }
    @media @bw768 {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 20px 20px;
    }
  }
  &__footer-link {
    margin-right: 50px;
    display: block;
    padding: 3px 0;
    font-weight: 500;
    text-decoration: none;
    font-family: @font1;
    font-size: 16px;
    line-height: 1.25;
    color: @white_60;
    transition: color 0.2s;
    @media @bw1170 {
      margin-right: 30px;
    }
    @media @bw768 {
      display: block;
      margin-right: 0px;
      margin-bottom: 10px;
      text-decoration: underline;
      font-size: 12px;
      line-height: 1.16;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &:active {
      color: @gold;
    }
    &:hover {
      @media (hover: hover) {
        color: @gold;
      }
    }
    &--mb20 {
      @media @bw768 {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
