import { defineRule, configure } from "vee-validate";
import { i18n } from "./lang-router";

function isEmpty(value) {
  if (value === null || value === undefined || value === "") {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  return false;
}

function isEmptyArray(arr) {
  return Array.isArray(arr) && arr.length === 0;
}

function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

//Custom Rules

//Custom Rule: required
//---------------------
const requiredValidator = (value) => {
  if (isNullOrUndefined(value) || isEmptyArray(value) || value === false) {
    return false;
  }
  return !!String(value).trim().length;
};
defineRule("required", requiredValidator);
//---------------------

//Custom Rule: email
//---------------------
const emailValidator = (value) => {
  if (isEmpty(value)) {
    return true;
  }
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (Array.isArray(value)) {
    return value.every((val) => re.test(String(val)));
  }

  return re.test(String(value));
};
defineRule("email", emailValidator);
//---------------------

configure({
  validateOnBlur: true,
  validateOnChange: true,
  validateOnInput: true,
  validateOnModelUpdate: true,
  generateMessage: (field) => {
    return i18n.global.t(`validation.${field.rule.name}`, field);
  }
});
