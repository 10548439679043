<template>
  <div
    class="request request--callme"
    :class="{ 'request--active': callMeActive }"
  >
    <div class="request__wrapper">
      <div class="request__semicircles"></div>
      <button type="button" class="request__close-btn r-btn" @click="close()">
        <svg-icon width="16" height="16" name="x" class="btn-icon" />
      </button>

      <div class="request__big-word big-word big-word--ls">call</div>
      <div class="request__container">
        <div class="request__item-info">
          <h4 class="request__title">
            <span class="request__title-word">
              {{ $t("callme.title[0]") }}
            </span>
            <span class="request__title-word">
              {{ $t("callme.title[1]") }}
            </span>
          </h4>
          <p class="request__text">
            {{ $t("callme.info") }}
          </p>
        </div>

        <FormInstance as="" v-slot="{ validate, values, setFieldValue }">
          <form
            ref="form"
            novalidate="novalidate"
            class="request__form"
            @submit.prevent="onSubmit(validate, values, setFieldValue)"
          >
            <div class="request__fields">
              <FormInputText
                class="request__field request__field--w50"
                :element="name"
                :validationCount="validationCount"
              />

              <FormInputText
                class="request__field request__field--w50"
                :element="phone"
                :validationCount="validationCount"
              />
            </div>

            <button type="submit" class="request__submit-btn btn">
              {{ $t("callme.submit") }}
            </button>

            <p class="request__agreement">
              {{ $t("agreement[0]") }}
              <br />
              <a
                target="_blank"
                href="/privacy"
                class="request__agreement-link"
              >
                {{ $t("agreement[1]") }}
              </a>
              {{ $t("agreement[2]") }}
            </p>
          </form>
        </FormInstance>
      </div>
    </div>
  </div>
</template>

<script>
import FormInputText from "@/components/form/FormInputText";

export default {
  components: {
    FormInputText
  },
  data() {
    return {
      validationCount: 0,
      isRequest: false
    };
  },
  computed: {
    callMeActive() {
      return this.$store.state.callMeActive;
    },
    api() {
      return this.$store.state.api;
    },
    name() {
      return {
        name: "name",
        label: this.$t("fields.name.label"),
        placeholder: this.$t("fields.name.ph"),
        rules: {
          required: true
        }
      };
    },
    phone() {
      return {
        name: "phone",
        label: this.$t("fields.phone.label"),
        placeholder: this.$t("fields.phone.ph"),
        mask: "+### (##) ##-##-##",
        rules: {
          required: true
        }
      };
    }
  },
  methods: {
    close() {
      this.$store.commit("closeAll");
    },
    onSubmit(validate, values, setFieldValue) {
      validate().then((result) => {
        this.validationCount += 1;
        if (result.valid) {
          this.onValidate(values, setFieldValue);
        }
      });
    },
    onValidate(values) {
      if (this.isRequest) return;

      this.isRequest = true;

      this.$axios({
        method: "post",
        url: this.api + "/callback",
        data: values,
        timeout: 90000
      })
        .then((response) => {
          this.isRequest = false;
          if (response && response.status == 200) {
            this.$toast.success(this.$t("requestMsg"));
            this.$store.state.callMeActive = false;
          } else {
            this.$toast.error(this.$t("error"));
          }
        })
        .catch((error) => {
          this.isRequest = false;
          this.$toast.error(this.$t("error"));
          if (error && error.response) console.log(error.response);
        });
    }
  },
  watch: {
    callMeActive: {
      handler: function (val) {
        if (!val && this.$root.$refs.scroll) this.$root.$refs.scroll.focus();
      },
      immediate: false
    }
  }
};
</script>
