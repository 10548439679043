<template>
  <header class="page-header" :class="{ 'page-header--tr': isTransparent }">
    <button
      type="button"
      class="page-header__call-btn r-btn"
      @click="openCallMe"
    >
      <svg
        width="24"
        height="24"
        class="btn-icon"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          class="btn-icon__phone"
          d="M18.12 17.0044C17.0267 17.0044 15.9689 16.8267 14.9822 16.5067C14.6711 16.4 14.3244 16.48 14.0844 16.72L12.6889 18.4711C10.1733 17.2711 7.81778 15.0044 6.56444 12.4L8.29778 10.9244C8.53778 10.6756 8.60889 10.3289 8.51111 10.0178C8.18222 9.03111 8.01333 7.97333 8.01333 6.88C8.01333 6.4 7.61333 6 7.13333 6H4.05778C3.57778 6 3 6.21333 3 6.88C3 15.1378 9.87111 22 18.12 22C18.7511 22 19 21.44 19 20.9511V17.8844C19 17.4044 18.6 17.0044 18.12 17.0044Z"
          fill="currentColor"
        />
        <path
          d="M16 7.97481L16.0002 3.47723M16 7.97481L21 3M16 7.97481L20.5 8"
          stroke="currentColor"
          stroke-width="2"
        />
      </svg>
    </button>

    <a v-if="phone" class="page-header__phone" :href="`tel:${phoneNumber}`">
      {{ phone }}
    </a>

    <localized-link to="/" class="page-header__logo" @click="closeAll()">
      <svg-icon
        width="210"
        height="98"
        name="logo"
        class="page-header__logo-icon"
      />
    </localized-link>

    <LangSwitcher class="page-header__lang-switcher" />

    <button
      type="button"
      class="page-header__nav-btn r-btn"
      :class="{ 'r-btn--switched': navActive }"
      @click="toggleNav()"
    >
      <span class="r-btn__wrapper">
        <span class="r-btn__switch">
          <svg-icon width="20" height="16" name="nav" class="btn-icon" />
        </span>
        <span class="r-btn__switch">
          <svg-icon width="14" height="14" name="x" class="btn-icon" />
        </span>
      </span>
    </button>
  </header>
</template>

<script>
import LangSwitcher from "@/components/LangSwitcher";

export default {
  components: {
    LangSwitcher
  },
  data() {
    return {};
  },
  computed: {
    navActive() {
      return this.$store.state.navActive;
    },
    isTransparent() {
      return this.$route.name === "index" || this.$route.name === "process";
    },
    contacts() {
      return this.$store.state.contacts;
    },
    phone() {
      return this.contacts.phone || "";
    },
    phoneNumber() {
      return this.$store.getters.phoneNumber;
    }
  },
  methods: {
    toggleNav() {
      this.$store.state.navActive = !this.$store.state.navActive;
    },
    openCallMe() {
      this.$store.state.callMeActive = true;
    },
    closeAll() {
      this.$store.commit("closeAll");
    }
  }
};
</script>

<style scoped lang="less">
.page-header {
  position: relative;
  width: 100%;
  height: 0;
  color: @white;
  z-index: 1000;
  @media @bw1020 {
    position: fixed;
    top: 0;
    left: 0;
  }
  &::before {
    display: none;
    @media @bw1020 {
      content: "";
      box-sizing: border-box;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 135px;
      border-bottom: 1px solid @white_10;
      background-color: @bg;
    }
    @media @bw768 {
      height: 115px;
    }
    @media @bw400 {
      height: 100px;
    }
  }
  &--tr {
    &::before {
      @media @bw1020 {
        display: none;
      }
    }
  }
  &__logo {
    display: block;
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 210px;
    height: 98px;
    @media @bw1510 {
      top: 30px;
      width: 160px;
      height: 75px;
    }
    @media @bw1020 {
      left: 30px;
      transform: none;
    }
    @media @bw768 {
      top: 20px;
      left: 20px;
    }
    @media @bw400 {
      width: 130px;
      height: 60px;
    }
    @media @w1510bh750 {
      top: 30px;
      width: 160px;
      height: 75px;
    }
  }
  &__logo-icon {
    display: block;
    width: 100%;
    height: 100%;
  }
  &__call-btn {
    position: absolute;
    top: 72px;
    left: 50px;
    @media @bw1510 {
      top: 40px;
      left: 30px;
    }
    @media @bw1020 {
      left: auto;
      right: 104px;
    }
    @media @bw768 {
      right: 94px;
      top: 30px;
    }
    @media @bw400 {
      right: 79px;
      top: 28px;
    }
    @media @w1510bh750 {
      top: 40px;
      left: 30px;
    }
  }
  &__phone {
    display: block;
    position: absolute;
    top: 90px;
    left: 124px;
    font-family: @font2;
    font-size: 22px;
    line-height: 1;
    color: @white;
    text-decoration: none;
    transition: 0.2s;
    &:hover,
    &:active {
      color: @gold;
    }
    @media @bw1510 {
      left: 104px;
      top: 58px;
    }
    @media @bw1020 {
      display: none;
    }
    @media @w1510bh750 {
      left: 104px;
      top: 58px;
    }
  }
  &__nav-btn {
    position: absolute;
    top: 72px;
    right: 50px;
    @media @bw1510 {
      top: 40px;
      right: 30px;
    }
    @media @bw768 {
      top: 30px;
      right: 20px;
    }
    @media @bw400 {
      top: 28px;
    }
    @media @w1510bh750 {
      top: 40px;
      right: 30px;
    }
  }
  &__lang-switcher {
    position: absolute;
    top: 82px;
    right: 130px;
    @media @bw1510 {
      top: 52px;
      right: 110px;
    }
    @media @bw1020 {
      display: none;
    }

    @media @w1510bh750 {
      top: 52px;
      right: 110px;
    }
  }
}
</style>
