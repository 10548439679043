import { createWebHistory } from "vue-router";
import { createLangRouter } from "./lang-router";
import { translations } from "./lang";

import routes from "vue-auto-routing";
import { createRouterLayout } from "vue-router-layout";

const RouterLayout = createRouterLayout((layout) => {
  return import("@/layouts/" + layout + ".vue");
});

const langOptions = {
  defaultLanguage: process.env.VUE_APP_I18N_LOCALE,
  translations: translations,
  localizedURLs: {}
};

const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: RouterLayout,
      children: routes
    },
    {
      path: "/:catchAll(.*)",
      component: RouterLayout,

      children: [
        {
          name: "page_404",
          path: "",
          component: () => import("@/views/page_404.vue")
        }
      ]
    }
  ]
};

const router = createLangRouter(langOptions, routerOptions);

export { router as default };
