export const global = {
  deepSearch: function (object, originalKey, originalValue, matches = []) {
    const $this = this;
    if (object != null) {
      if (Array.isArray(object)) {
        for (let arrayItem of object) {
          $this.deepSearch(arrayItem, originalKey, originalValue, matches);
        }
      } else if (typeof object == "object") {
        for (let key of Object.keys(object)) {
          if (key === originalKey && object[key] === originalValue) {
            matches.push(object);
          } else {
            $this.deepSearch(object[key], originalKey, originalValue, matches);
          }
        }
      }
    }

    return matches;
  },
  numberSpaces(x, rank) {
    if (!x) return x === 0 ? 0 : "";
    const num =
      rank && !isNaN(x) && !Number.isInteger(+x) ? (+x).toFixed(rank) : x;

    const arr = num.toString().split(".");
    arr[0] = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return arr.join(".");
  },
  arrToObj(arr, key = true, byIndex) {
    if (!Array.isArray(arr)) return {};
    return byIndex
      ? Object.assign({}, arr)
      : arr.reduce((acc, curr) => ((acc[curr] = key), acc), {});
  }
};
