<template>
  <div
    class="preloader"
    :class="{
      'preloader--active': isActive,
      'preloader--open': isOpen,
      'preloader--close': isClose
    }"
  >
    <div class="preloader__bg">
      <div class="preloader__layer">
        <svg
          class="preloader__bg-logo"
          viewBox="0 0 1067 1823"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1067 174.076L416.596 0L438.539 490.98L0 608.351L68.4636 1823H581.941L599.86 1531.96L998.536 1531.87L1067 174.076ZM102.695 677.982L547.709 573.536L479.245 1718.55H171.159L102.695 677.982ZM650.404 434.276L608.834 1427.42H895.841L964.305 243.706L519.291 139.261L536.59 464.737L650.404 434.276Z"
            fill="#F3F3F2"
          />
        </svg>

        <div class="preloader__logo">
          <svg
            viewBox="0 0 210 98"
            class="preloader__logo-svg"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g fill="currentColor">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M100 3.3L87.2 0l.4 9.4-8.6 2.3L80.3 35h10.2l.3-5.6h7.9l1.3-26zM81 13l8.8-2-1.4 22h-6L81 13zm10.8-4.7L91 27.4h5.6L98 4.7l-8.8-2 .4 6.2 2.2-.6zM111 3.3L123.8 0l-.4 9.4 8.6 2.3-1.3 23.3h-10.2l-.3-5.6h-7.9l-1.3-26zm19 9.7l-8.8-2 1.4 22h6l1.4-20zm-10.8-4.7l.8 19.1h-5.6L113 4.7l8.8-2-.4 6.2-2.2-.6z"
              />
              <path
                d="M4.2 54.7H1.8v9.4h2.4c.6 0 .8-.2.8-.8v-7.7c0-.6-.2-.9-.8-.9zM0 53h4.2c1.7 0 2.6.9 2.6 2.6v7.7c0 1.7-.9 2.6-2.6 2.6H1.8V77H0V53zM30.3 73.6L30 77H28l2.8-24h2.1l2.8 24h-2l-.3-3.4h-3zm3-1.7l-1.5-10.4-1.5 10.4h3zM59.2 63v14h-2.3V53h2l4 14V53H65v24h-2l-4-14zM89.6 54.7c-.6 0-1.9.3-1.9.9v18.8c0 .6 1.3.9 2 .9.5 0 1.8-.3 1.8-.9V55.6c0-.6-1.3-.9-1.9-.9zm-3.6.9c0-1.7 1.9-2.6 3.6-2.6 1.8 0 3.7.9 3.7 2.6v18.8c0 1.7-2 2.6-3.7 2.6-1.7 0-3.6-.9-3.6-2.6V55.6zM118.6 54.7h-2.4v9.4h2.4c.6 0 .8-.2.8-.8v-7.7c0-.6-.3-.9-.8-.9zm-4.2-1.7h4.2c1.7 0 2.6.9 2.6 2.6v7.7c0 1-.3 1.7-.8 2l.8 11.7h-2l-.7-11.1h-2.3V77h-1.8V53zM174 60.2L173 77h-1.7l1-24h3.2l.8 12 1-12h3l1.1 24h-1.7l-1-16.8-1.3 16.8h-2.1l-1.2-16.8zM145 73.6l-.4 3.4h-2l2.8-24h2.1l2.8 24h-2l-.3-3.4h-3zm3-1.7l-1.6-10.4-1.4 10.4h3zM204.6 73.6l-.3 3.4h-2l2.8-24h2.1l2.8 24h-2l-.3-3.4h-3zm3.1-1.7l-1.6-10.4-1.5 10.4h3.1zM0 95h30v1H0zM181 95h29v1h-29zM51.5 93.9h1.3c.7 0 1.2.3 1.2.7 0 .5-.5.8-1.2.8h-1.3V94zm0 2.2h1.2L54 98h1.7L54.2 96c.8-.2 1.3-.8 1.3-1.4 0-1-1-1.5-2.6-1.5H50v4.8h1.5v-1.8zM68.3 97H65V96h3V95h-3v-1.2h3.2v-.8h-4.7v4.8h4.7v-.8zM76.2 96.5c0 1 1.1 1.5 2.8 1.5 1.8 0 3-.6 3-1.5 0-.8-.7-1.2-2.1-1.4L79 95c-.8-.1-1.2-.3-1.2-.6 0-.4.5-.6 1.3-.6.7 0 1.2.2 1.3.7h1.4c0-1-1-1.5-2.7-1.5-1.6 0-2.8.6-2.8 1.5 0 .7.7 1.1 2 1.3l1 .2c.8.1 1.1.3 1.1.6s-.5.6-1.3.6-1.4-.3-1.5-.7h-1.4zM91.4 98v-5h-1.5v5h1.5zM99.7 93v5h2.7c2.1 0 3.4-1 3.4-2.5 0-1.6-1.3-2.4-3.4-2.4h-2.7zm1.4 1h1.1c1.3 0 2 .5 2 1.5s-.6 1.6-2 1.6h-1v-3.2zM118.5 97h-3.2V96h3V95h-3v-1.2h3.2v-.8h-4.7v4.8h4.7v-.8zM128.1 98v-3.2l3.4 3.1h1.2v-4.8h-1.4v3.1l-3.4-3.1h-1.2v4.8h1.4zM144.1 98c1.8 0 3-.7 3.2-1.8h-1.5c-.1.6-.8 1-1.6 1-1.2 0-1.9-.7-1.9-1.7s.7-1.7 1.8-1.7c1 0 1.6.4 1.7 1h1.5c-.1-1-1.4-1.8-3.2-1.8-2 0-3.3 1-3.3 2.5 0 1.6 1.3 2.5 3.3 2.5zM160 97h-3.2V96h3V95h-3v-1.2h3.2v-.8h-4.7v4.8h4.7v-.8z"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: true,
      isOpen: true,
      isClose: false,
      isFull: true,
      openTime: 0
    };
  },
  computed: {
    appLoaded() {
      return this.$store.state.appLoaded;
    }
  },
  methods: {
    show() {
      if (this.isActive) return;
      this.isActive = true;
      this.isOpen = true;

      this.openTime = new Date();

      setTimeout(() => {
        this.isFull = true;
      }, 750);
    },
    hide() {
      if (!this.isActive) return;
      if (this.isFull) {
        this.isClose = true;
        this.isOpen = false;

        setTimeout(() => {
          this.isFull = false;
          this.isClose = false;

          this.$nextTick(() => {
            this.isActive = false;
            if (this.$root.$refs.scroll) this.$root.$refs.scroll.focus();
          });
        }, 750);
      } else {
        const closeTime = new Date();
        const diff = closeTime - this.openTime;

        setTimeout(
          () => {
            this.isFull = true;
            this.isClose = true;
            this.isOpen = false;

            setTimeout(() => {
              this.isFull = false;
              this.isClose = false;
              this.$nextTick(() => {
                this.isActive = false;
                if (this.$root.$refs.scroll) this.$root.$refs.scroll.focus();
              });
            }, 750);
          },
          diff < 750 ? 750 - diff : 750
        );
      }
    }
  },
  created() {
    this.openTime = new Date();

    this.$emitter.on("show-preloader", () => {
      this.show();
    });
    this.$emitter.on("hide-preloader", () => {
      if (this.appLoaded) {
        this.hide();
      } else {
        const closeTime = new Date();
        const diff = closeTime - this.openTime;

        setTimeout(
          () => {
            this.hide();
          },
          diff < 800 ? 800 - diff : 800
        );
      }
    });
  }
};
</script>

<style scoped lang="less">
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  visibility: hidden;
  pointer-events: none;
  overflow: hidden;
  &--active {
    visibility: visible;
    pointer-events: auto;
  }
  &--open {
    & .preloader__bg {
      transform: translate3d(0, 0, 0);
      transition: transform 0.7s cubic-bezier(0.1, 0.1, 0.25, 0.9);
    }
    & .preloader__layer {
      transform: translate3d(0, 0, 0);
      transition: transform 0.7s cubic-bezier(0.1, 0.1, 0.25, 0.9);
    }
  }
  &--close {
    & .preloader__bg {
      transform: translate3d(0, -100%, 0);
      transition: transform 0.7s cubic-bezier(0.1, 0.1, 0.25, 0.9);
    }
    & .preloader__layer {
      transform: translate3d(0, 100%, 0);
      transition: transform 0.7s cubic-bezier(0.1, 0.1, 0.25, 0.9);
    }
  }
  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: lighten(@bg, 5%);
    transform: translate3d(0, 100%, 0);
    overflow: hidden;
    z-index: 0;
  }
  &__layer {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transform: translate3d(0, -100%, 0);
  }
  &__bg-logo {
    position: absolute;
    top: 45%;
    left: 52%;
    transform: translate(-50%, -50%);
    width: 42%;
    min-width: 400px;
    background-image: url("~@/assets/images/bg_logo.svg");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.02;
    z-index: -1;
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 170.8528%;
    }
  }
  &__logo {
    flex-shrink: 0;
    position: relative;
    margin: auto;
    width: 240px;
    color: @white_40;
    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: 66.67%;
    }
  }
  &__logo-svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
