export default {
  fullList: (state) => {
    if (!state.flats) return null;
    return Object.values(state.flats).filter((flat) => {
      return flat.status !== "rent";
    });
  },
  list: (state, getters) => {
    if (!getters.fullList) return null;
    return Object.values(getters.fullList).filter((flat) => {
      return flat.status === "free";
    });
  },
  listRent: (state) => {
    if (!state.flats) return null;
    return Object.values(state.flats).filter((flat) => {
      return flat.status === "rent";
    });
  },
  floors: (state) => {
    if (!state.flats) return null;
    const floors = {};
    Object.values(state.flats).forEach((flat) => {
      const highlights = [flat.highlight];
      if (flat.highlight_1) highlights.push(flat.highlight_1);

      highlights.forEach((highlight, i) => {
        const current = Object.assign({}, flat);
        current.highlight = highlight;
        const floor = +current.floor + i;
        if (!floors[floor]) floors[floor] = [];
        floors[floor].push(current);
      });
    });
    return floors;
  },
  hasFilter: (state) => {
    return Object.keys(state.filter).length;
  },
  phoneNumber: (state) => {
    return state.contacts.phone
      ? state.contacts.phone.replace(/[^+0-9]/g, "")
      : "";
  },
  schedule: (state) => {
    return state.contacts.time_start && state.contacts.time_end
      ? `${state.contacts.time_start} - ${state.contacts.time_end}`
      : "";
  }
};
