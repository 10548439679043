const translations = {
  en: {
    name: "English",
    load: () => {
      return import("@/locales/en.json");
    }
  },
  lv: {
    name: "Latvian",
    load: () => {
      return import("@/locales/lv.json");
    }
  },
  ru: {
    name: "Russian",
    load: () => {
      return import("@/locales/ru.json");
    }
  }
};

function supportedLocalesInclude(locale) {
  return Object.keys(translations).includes(locale);
}

function getBrowserLocale(options = {}) {
  const defaultOptions = { countryCodeOnly: false };
  const opt = { ...defaultOptions, ...options };
  const navigatorLocale =
    navigator.languages !== undefined
      ? navigator.languages[0]
      : navigator.language;
  if (!navigatorLocale) {
    return undefined;
  }
  const trimmedLocale = opt.countryCodeOnly
    ? navigatorLocale.trim().split(/-|_/)[0]
    : navigatorLocale.trim();
  return trimmedLocale;
}

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE;
  }
}

let currentLang = localStorage.getItem("VueAppLanguage");
if (!currentLang) {
  currentLang = getStartingLocale();
  localStorage.setItem("VueAppLanguage", currentLang);
}

export { currentLang, translations };
