<template>
  <label class="field">
    <FormField
      v-if="element"
      :name="element.name"
      :label="'«' + element.label + '»'"
      :rules="element.rules"
      :value="initialValue"
      v-slot="{ field, value, handleChange, errorMessage }"
    >
      <span
        class="field__label"
        :class="{
          'field__label--required': element.rules && element.rules.required
        }"
        >{{ element.label }}</span
      >
      <span
        class="field__wrap"
        :class="{
          'field__wrap--focus': isFocused,
          'field__wrap--error':
            errorMessage && (isNaN(validationCount) || validationCount > 0)
        }"
      >
        <input
          v-bind="field"
          v-maska="setMask()"
          :placeholder="element.placeholder"
          type="text"
          class="field__input"
          @focus="isFocused = true"
          @blur="isFocused = false"
        />

        <span
          v-if="element.clearable && value"
          class="field__btn field__btn--x"
          tabindex="-1"
          @click="handleChange('')"
        >
          <svg-icon width="16" height="16" name="x" class="field__btn-icon" />
        </span>
      </span>
      <span
        v-if="errorMessage && (isNaN(validationCount) || validationCount > 0)"
        class="field__error"
      >
        {{ errorMessage }}
      </span>
    </FormField>
  </label>
</template>

<script>
export default {
  props: ["element", "initialValue", "validationCount"],
  data() {
    return {
      isFocused: false
    };
  },
  methods: {
    setMask() {
      if (!this.element.mask) return undefined;
      try {
        return JSON.parse(this.element.mask);
      } catch (e) {
        return this.element.mask;
      }
    }
  }
};
</script>
