import { createStore } from "vuex";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

export default createStore({
  state: {
    api: process.env.VUE_APP_API,
    domain: process.env.VUE_APP_DOMAIN,
    appLoaded: false,
    available: true,
    callMeActive: false,
    navActive: false,
    requestActive: false,
    filterActive: false,
    filter: {},
    contacts: {},
    current_page: 1,
    per_page: 10,
    sort: ["floor", "asc"],
    flats: null,
    floor: 0,
    flat_id: 0,
    activeRow: 0,
    isTile: false,
    per_sqr: 0,
    price_on_request: 0,
    processData: null,
    showRentList: false,
    sliders: {}
  },
  getters,
  mutations,
  actions,
  modules: {}
});
