import { createApp, defineAsyncComponent } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";

import { i18n } from "./bootstrap/lang-router";
import router from "./bootstrap/router";
import store from "./store";

import VueSocialSharing from "vue-social-sharing";

import Toaster from "@meforma/vue-toaster";
import vueScrollto from "vue-scrollto";
// import VueDeferredContent from "vue3-deferred-content";

import VueHammer from "./bootstrap/vue3-hammer";

import { createVueMatchMediaPlugin } from "@cwist/vue-match-media";

const breakpoints = {
  bw1170: { maxWidth: 1169 },
  bw400: { maxWidth: 399 }
};

import Maska from "maska";
import { tokens } from "maska";

// default tokens:
// {
//   '#': { pattern: /[0-9]/ },
//   'X': { pattern: /[0-9a-zA-Z]/ },
//   'S': { pattern: /[a-zA-Z]/ },
//   'A': { pattern: /[a-zA-Z]/, uppercase: true },
//   'a': { pattern: /[a-zA-Z]/, lowercase: true },
//   '!': { escape: true },
//   '*': { repeat: true }
// }

const newTokens = {
  R: { pattern: /[а-яА-Я-\s()]/ },
  E: { pattern: /[a-zA-Z-\s()]/ },
  M: { pattern: /[a-zA-Zа-яА-Я-\s()]/ },
  P: { pattern: /[\d+()-\s]/ }
};
Object.assign(tokens, newTokens);

import VueSlider from "vue-slider-component";

import { Form, Field, ErrorMessage } from "vee-validate";
require("./bootstrap/VeeValidate");

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(Toaster, {
    position: "top-right",
    duration: 5000,
    useDefaultCss: false
  })
  .use(vueScrollto)
  // .use(VueDeferredContent, {
  //   name: "lazyContent",
  //   autoHide: true,
  //   rootMargin: "0px",
  //   threshold: 0
  // })
  .use(VueHammer)
  .use(VueSocialSharing)
  .use(createVueMatchMediaPlugin({ breakpoints }))
  .use(Maska)
  .component(
    "svg-icon",
    defineAsyncComponent(() => import("./components/SvgIcon"))
  )
  .component("VueSlider", VueSlider)
  .component("FormInstance", Form)
  .component("FormField", Field)
  .component("FormError", ErrorMessage)
  .directive("scroll", {
    mounted: function (el, binding) {
      binding.dir.f = function (evt) {
        binding.value(evt, el);
      };
      window.addEventListener("scroll", binding.dir.f);
    },
    unmounted(el, binding) {
      document.removeEventListener("scroll", binding.dir.f);
    }
  })
  .directive("resize", {
    mounted: function (el, binding) {
      binding.dir.f = function (evt) {
        binding.value(evt, el);
      };
      window.addEventListener("resize", binding.dir.f);
    },
    unmounted(el, binding) {
      document.removeEventListener("resize", binding.dir.f);
    }
  })
  .directive("keyup", {
    mounted: function (el, binding) {
      binding.dir.f = function (evt) {
        binding.value(evt, el);
      };
      document.addEventListener("keyup", binding.dir.f);
    },
    unmounted(el, binding) {
      document.removeEventListener("keyup", binding.dir.f);
    }
  });

import axios from "axios";
app.config.globalProperties.$axios = axios;

import { global } from "./bootstrap/global.js";
app.config.globalProperties.$global = global;

import mitt from "mitt";
const emitter = mitt();
app.config.globalProperties.$emitter = emitter;

router.isReady().then(() => app.mount("#app"));

// --- Swiper >>>
// import VueAwesomeSwiper from "vue-awesome-swiper";
// Vue.use(VueAwesomeSwiper);

// import {
//   Swiper as SwiperClass,
//   Pagination,
//   Navigation,
//   Mousewheel
// } from "swiper/swiper.esm";
// import getAwesomeSwiper from "vue-awesome-swiper/dist/exporter";

// SwiperClass.use([Pagination, Mousewheel, Navigation]);
// Vue.use(getAwesomeSwiper(SwiperClass));
// --- Swiper <<<
