<template>
  <div class="card-cookies" :class="{ 'card-cookies--active': active }">
    <div class="card-cookies__text">
      {{ $t("card_cookies") }}

      <localized-link to="/cookies" class="card-cookies__link">
        cookies
      </localized-link>
    </div>
    <button type="button" class="card-cookies__btn" @click="ok()">
      <svg-icon width="15" height="18" class="btn-icon" name="ok" />
    </button>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      active: false
    };
  },
  methods: {
    ok() {
      localStorage.setItem("VueAppAgreement", 1);
      this.active = false;
    }
  },
  created() {
    const agreement = localStorage.getItem("VueAppAgreement");
    if (!agreement || agreement != 1) {
      this.active = true;
    }
  }
};
</script>

<style scoped lang="less">
.card-cookies {
  position: fixed;
  right: 50px;
  bottom: 115px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 25px;
  width: 375px;
  max-width: calc(100% - 40px);
  border-radius: 4px;
  background-color: @gold;
  color: @dark2;
  transition: 0.3s ease;
  transform: translate3d(430px, 0, 0);
  z-index: 1500;
  @media @bw1510 {
    right: 30px;
    bottom: 85px;
  }
  @media @bw768 {
    right: 20px;
    bottom: 75px;
  }

  @media @bw600 {
    bottom: 20px;
  }

  @media @bw1170w600bh750 {
    bottom: 55px;
  }

  @media @bw1170w600bh550 {
    bottom: 20px;
  }

  @media @w1510bh750 {
    right: 30px;
    bottom: 85px;
  }

  &--active {
    transform: translate3d(0, 0, 0);
  }

  &__text {
    margin-right: 30px;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.3;
    @media @bw450 {
      margin-right: 20px;
    }
  }
  &__link {
    color: @dark2;
    text-transform: lowercase;
    transition: text-decoration-color 0.2s;
    &:hover,
    &:active {
      color: @dark2;
      text-decoration-color: transparent;
    }
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px solid @dark2;
    outline: none;
    color: @dark2;
    transition: color 0.2s, background-color 0.2s;
    &:hover {
      background-color: @dark2;
      color: @gold;
    }
  }
}
</style>
